<template>
  <div class="service-description-box">
    <div class="service-description">
      <div class="header-title">主站业务</div>
      <p>任务背景:</p>
      <p>{{task_background}}</p>
      <p style="margin-top: 40px">任务要求:</p>
      <p>{{task_request}}</p>

      <p style="margin-top: 40px">网店入驻要求:</p>
      <p>请确保您的企业营业执照、身份证正反面、税务登记证、增值税一般纳税人登记证均有效；</p>
<!--      <p>2、请确保您所拥有的品牌有效期或已获得相关授权；</p>-->
<!--      <p>3、请确保您所售商品已取得国家规定的相关行业资质。</p>-->

      <div class="btn-box">
        <el-button class="btn-red" @click="dialogVisible = true">开始任务</el-button>
        <el-button @click="downloadBtn">素材下载</el-button>
      </div>
    </div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="提示信息"
        :visible.sync="dialogVisible"
        width="500px">
      <div class="dialog-content">
        <div style="color: #666">请确认并准备您企业获得的资质：</div>
        <p>企业营业执照、身份证正反面、税务登记证、增值税一般纳税人登记证</p>
<!--        <p>2、经营商品所属行业相关资质证明</p>-->
<!--        <p>3、经营商品所属品牌授权相关证明</p>-->
        <div style="color: #666">如已符合以上几点，请点击继续入驻。</div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn-red" style="margin-left: 20px" @click="toEnter">确定，开始任务</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileDownload: null,
      dialogVisible: false,
      task_background: '',
      task_request: '',
    }
  },
  created() {
    // this.getInfo()
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$http.axiosGet(this.$api.stuStoreSettingsTemInfo, (res) => {
        if (res.code === 200) {
          this.task_background = res.data.task_background
          this.task_request = res.data.task_request
         } else {
          this.$message.warning(res.msg)
        }
      })
    },
    getInfo() {
      this.$http.axiosGet(this.$api.stuStoreSettingsStoreSettingMain, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            this.$router.replace({
              path: '/student/storeRegister/set',
              query: {...this.$route.query, isAnswer: 1} // 0: 未答题， 1:已答题
            })
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    toEnter() {
      this.$router.push('/student/storeRegister/set')
    },
    downloadBtn() {
      let url = 'stu_store_settings/download?com_id=' + localStorage.getItem('com_id')
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.service-description-box {
  width: 100%;
  padding: 90px 0;
}
.service-description {
  width: 720px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 0 16px 0 rgba(43, 43, 43, 0.09);
  p {
    font-size: 16px;
    margin-bottom: 0;
  }
}
.header-title {
  margin: 0 0 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
}
.btn-box {
  text-align: center;
  margin-top: 30px;
}
.dialog-content {
  color: #333;
}
</style>